import {
  CONTRACT_RESOURCE,
  ANNOUNCEMENT_RESOURCE,
  DELETE_ACTION,
  SUPPORT_TICKET,
  READ_ACTION
} from "@/constants/resources";

export default [
  {
    title: "Notificações",
    icon: "none",
    children: [
      {
        title: "Notificações recebidas",
        route: "notification-inbox-unread",
        icon: "BellIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Gestão de chamados",
        route: "support-tickets",
        icon: "MessageSquareIcon",
        resource: SUPPORT_TICKET,
        action: READ_ACTION,
      },
      {
        title: "Comunicados",
        route: "announcements-list",
        icon: "SendIcon",
        resource: ANNOUNCEMENT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Exclusão de contrato",
        route: "deletion-request-list",
        icon: "XCircleIcon",
        resource: CONTRACT_RESOURCE,
        action: DELETE_ACTION,
      },
    ],
  },
];
