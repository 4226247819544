import {
  ANNOUNCEMENT_RESOURCE,
  DASHBOARD_BANNER,
  READ_ACTION,
  PROFILE,
  MANAGE_ACTION,
} from "@/constants/resources";

export default [
  {
    title: "Configurações",
    icon: "none",
    children: [
      {
        title: "Lista de perfis",
        route: "access-profiles-list",
        icon: "ListIcon",
        resource: PROFILE,
        action: MANAGE_ACTION,
      },
      {
        title: "Gerenciamento de banners",
        route: "dashboard-banners",
        icon: "ImageIcon",
        resource: DASHBOARD_BANNER,
        action: READ_ACTION,
      },
    ],
  },
];
